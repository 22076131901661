import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';



const App = () => (
  <Router>
  
    <main style={{ minHeight: 'calc(100vh - 80px)' }}> 
      <Routes>
        <Route path="/" element={<Home />} />
       
     
  
      </Routes>
    </main>
  
  </Router>
);

export default App;
